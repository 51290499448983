export const foodAllergenInfo = [
  {
    text: "🌾 มีส่วนผสมของกลูเตนหรือถั่วเหลือง",
    label: "แพ้กลูเตนหรือถั่วเหลือง",
    value: "contain-gluten",
  },
  {
    text: "🥛 มีส่วนผสมของถั่วหรือนม",
    label: "แพ้ถั่วหรือนม",
    value: "contain-dairy",
  },
  {
    text: "🦐 มีส่วนประกอบของอาหารทะเลเปลือกแข็ง",
    label: "แพ้อาหารทะเลเปลือกแข็ง",
    value: "contain-shellfish",
  },
  {
    text: "🍾 มีส่วนผสมของแอลกอฮอล์",
    label: "แพ้แอลกอฮอล์",
    value: "contain-alcohol",
  },
  {
    text: "☕️ มีส่วนผสมของคาเฟอีน",
    label: "แพ้คาเฟอีน",
    value: "contain-caffeine",
  },
];
