import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useMediaQuery,
  useTheme,
} from "@suid/material";
import { TransitionProps } from "@suid/material/transitions";
import { Accessor, JSXElement } from "solid-js";
import toast from "solid-toast";
import { API_URL } from "../../config";

import useAuth from "../../hooks/useAuth";
import { useFirebaseApp } from "../../hooks/useFirebaseApp";

const Transition = function Transition(
  props: TransitionProps & {
    children: JSXElement;
  }
) {
  return <Slide direction="up" {...props} />;
};

export default function RemoveFoodDialog({
  open,
  handleClose,
  foodName,
  id,
  refetch,
}: {
  open(): boolean;
  handleClose(reason: "success" | "error" | "cancel"): void;
  foodName(): string;
  id: Accessor<string>;
  refetch(): void;
}) {
  const app = useFirebaseApp();
  const { getToken } = useAuth(app);
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down("md"));

  const handleDeleteFood = async () => {
    const token = await getToken();
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const deleteFood = await fetch(
        `${API_URL}/foods/${id()}`,
        requestOptions
      );
      toast.promise(deleteFood.json(), {
        loading: "กำลังลบเมนูอาหาร",
        success: "ลบเมนูอาหารสำเร็จ ✅",
        error: "เกิดข้อผิดพลาด 😔",
      });
      handleClose("success");

      await new Promise((resolve) => setTimeout(resolve, 500));
      refetch();
    } catch (error) {
      handleClose("error");
      toast.error(error.message);
    }
  };

  return (
    <Dialog
      fullWidth={fullWidth()}
      open={open()}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>ยืนยันการลบ</DialogTitle>
      <DialogContent>
        <DialogContentText component="p" id="alert-dialog-slide-description">
          ยืนยันการลบรายการ "{foodName()}"
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={() => {
            handleClose("cancel");
          }}
          color="info"
          size="large"
          sx={{ px: 3 }}
          variant="outlined"
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleDeleteFood}
          color="error"
          size="large"
          sx={{ px: 3 }}
          variant="contained"
        >
          ลบ
        </Button>
      </DialogActions>
    </Dialog>
  );
}
