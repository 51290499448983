import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@suid/material";
import { For, createSignal, onMount, Show } from "solid-js";

import type { InputBaseProps } from "@suid/material/InputBase";
import type { SelectChangeEvent } from "@suid/material/Select";

export interface Values {
  text: string;
  value: number | string;
}

type FormProps = {
  setData(key: string, value: any): void;
  name: string;
  label: string;
  required?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  values: Values[];
  initialValue?: any;
  errors?: any;
  onChange?: (event: SelectChangeEvent) => void;
  disabled?: () => boolean;
};

export default function FormSelector({
  disabled = () => false,
  ...componentProps
}: FormProps) {
  const [value, setValue] = createSignal(componentProps.initialValue ?? "");

  onMount(() => {
    componentProps.setData(componentProps.name, componentProps.initialValue);
  });

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    componentProps.setData(componentProps.name, event.target.value);
    componentProps.onChange?.(event);
  };

  return (
    <FormControl
      fullWidth
      required={componentProps?.required}
      error={!!componentProps.errors?.()[componentProps.name]}
    >
      <InputLabel id="label">{componentProps.label}</InputLabel>
      <Select
        labelId="label"
        name={componentProps.name}
        value={value()}
        onChange={handleChange}
        label={componentProps.label}
        disabled={disabled()}
      >
        <For each={componentProps.values}>
          {({ value, text }) => <MenuItem value={value}>{text}</MenuItem>}
        </For>
      </Select>
      <Show when={!!componentProps.errors}>
        <FormHelperText>
          {componentProps?.errors?.()[componentProps.name]}
        </FormHelperText>
      </Show>
    </FormControl>
  );
}
