import FormControl from "@suid/material/FormControl";
import InputLabel from "@suid/material/InputLabel";
import MenuItem from "@suid/material/MenuItem";
import OutlinedInput from "@suid/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@suid/material/Select";
import { For, createSignal } from "solid-js";
import { foodFilters } from "../../data/foodFilter";
import foodFilterStore from "../../stores/foodFilterStore";
import { useSearchParams } from "@solidjs/router";

export default function FoodFilterSelector() {
  const [filterValue, setFilterValue] = createSignal<string[]>([]);
  const [, setSearchParams] = useSearchParams();
  const { setFoodFilter } = foodFilterStore;

  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;

    setFilterValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setSearchParams({
      filter: String(value),
    });
    setFoodFilter(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl fullWidth sx={{ flexGrow: 1, maxWidth: "300px" }}>
      <InputLabel id="label" variant="outlined">
        ค้นหาขั้นสูง
      </InputLabel>
      <Select
        labelId="label"
        name="search-filter"
        multiple
        value={filterValue()}
        onChange={handleChange}
        input={<OutlinedInput id="label" label="ค้นหาขั้นสูง" />}
        renderValue={(selected) => {
          const text = selected.map((value) => {
            const found = foodFilters.find((info) => info.value === value);
            return found ? found.label : null;
          });
          return text.join(", ");
        }}
      >
        <For each={foodFilters}>
          {({ label, value }) => <MenuItem value={value}>{label}</MenuItem>}
        </For>
      </Select>
    </FormControl>
  );
}
