import { createSignal, createRoot } from "solid-js";

function uploadStore() {
  const [previewUrl, setPreviewUrl] = createSignal<string>("");
  const [croppedUrl, setCroppedUrl] = createSignal<string>("");
  const [hasCropped, setHasCropped] = createSignal<boolean>(false);
  return {
    previewUrl,
    setPreviewUrl,
    croppedUrl,
    setCroppedUrl,
    hasCropped,
    setHasCropped,
  };
}

export default createRoot(uploadStore);
