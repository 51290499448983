import { api } from "./axios";
import { API_URL } from "../config";
import { parseURLSearchParams } from "../utils";

import type {
  GetRestaurants,
  FetchRestaurantsInput,
  CreateRestaurantInput,
  Restaurant,
} from "../models/restaurant";
import { UpdateRestaurantInput } from "../models/restaurant";

export async function fetchRestaurants({
  query = "",
  page = 1,
}: FetchRestaurantsInput) {
  const params = parseURLSearchParams({ query, page: page.toString() });

  const { data } = await api.get<GetRestaurants>(
    `${API_URL}/restaurants/admin?${params}`
  );
  return data;
}

export async function uploadRestaurantImage({
  formData,
}: {
  formData: FormData;
}) {
  return await api.put(`${API_URL}/restaurants/upload`, formData);
}

export async function getRestaurant(id: string) {
  const { data } = await api.get<Restaurant>(`${API_URL}/restaurants/${id}`);
  return data;
}

export async function createRestaurant(data: CreateRestaurantInput) {
  return await api.post(`${API_URL}/restaurants`, data);
}

export async function updateRestaurant({ id, ...data }: UpdateRestaurantInput) {
  return await api.patch(`${API_URL}/restaurants/${id}`, data);
}
