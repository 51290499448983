import { createPagination } from "@solid-primitives/pagination";
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from "@suid/icons-material";
import { Box, ButtonBase, useTheme } from "@suid/material";
import { createEffect, For, Show } from "solid-js";

export default function PaginationFooter({
  pages,
  setSearchParams,
  currentPage,
}: {
  pages(): number;
  setSearchParams(s: any): void;
  currentPage: string | undefined;
}) {
  const theme = useTheme();
  const [paginationProps, page] = createPagination({
    pages: pages(),
    initialPage: Number(currentPage) ?? 1,
    showFirst: false,
    showLast: false,
    firstContent: <FirstPage sx={{ color: theme.palette.info.main }} />,
    lastContent: <LastPage sx={{ color: theme.palette.info.main }} />,
    prevContent: <ChevronLeft sx={{ color: theme.palette.info.main }} />,
    nextContent: <ChevronRight sx={{ color: theme.palette.info.main }} />,
  });

  createEffect(() => setSearchParams({ page: page() }));

  return (
    <Show when={pages() > 1}>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          background: "#fff",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            padding: 1.5,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <For each={paginationProps()}>
            {(props) => (
              <ButtonBase
                sx={{
                  borderRadius: "999px",
                  backgroundColor:
                    props.page === page()
                      ? theme.palette.primary.main
                      : "transparent",
                  color: props.page === page() ? "#fff" : "#000000DE",
                  width: "40px",
                  height: "40px",
                  fontSize: "0.875rem",
                  "&:hover": {
                    backgroundColor:
                      props.page !== page()
                        ? "#00000014"
                        : theme.palette.primary.main,
                  },
                  "&:disabled": {
                    ".MuiSvgIcon-root": { color: "text.disabled" },
                  },
                }}
                {...props}
              />
            )}
          </For>
        </Box>
      </Box>
    </Show>
  );
}
