import { JSX, createSignal } from "solid-js";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { createStore } from "solid-js/store";
import fileStore from "../../stores/uploadStore";
import CloseIcon from "@suid/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  CardMedia,
  Typography,
} from "@suid/material";
import { Transition } from "solid-transition-group";

export default function ImageCropperModal({
  open,
  handleClose,
  aspect = 3 / 2,
}: {
  open(): boolean;
  handleClose(): void;
  aspect?: number;
}) {
  let cropImage!: HTMLImageElement;

  const {
    setPreviewUrl,
    previewUrl,
    setCroppedUrl,
    croppedUrl,
    setHasCropped,
  } = fileStore;
  const [cropper, setCropper] = createSignal<any>(null);
  const [state, setState] = createStore({
    error: null,
    croppedImage: "",
  });

  const handleImageLoad: JSX.EventHandlerUnion<HTMLImageElement, Event> = (
    e
  ) => {
    const { width, height } = e.currentTarget;
    setCropper(
      new Cropper(cropImage, {
        aspectRatio: aspect,
        viewMode: 1,
        minCanvasWidth: 500,
        minCropBoxWidth: 500,
        zoomOnTouch: false,
        zoomOnWheel: false,
        zoomable: false,
        rotatable: false,
        data: {
          width,
          height,
        },
      })
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={open()}
      TransitionComponent={Transition}
      onClose={() => {
        cropper().clear();
        handleClose();
      }}
    >
      <IconButton
        onClick={() => {
          cropper().reset();
          handleClose();
        }}
        sx={{ position: "absolute", right: 8, top: 8, m: 0 }}
        edge="end"
        title="close"
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Typography variant="h6">ครอบตัดรูปภาพ</Typography>
      </DialogTitle>
      <DialogContent>
        <CardMedia
          component="img"
          ref={cropImage}
          sx={{
            display: "block",
            maxWidth: "100%",
            height: "100%",
            border: "1px solid rgba(0, 0, 0, 0.23)",
          }}
          image={previewUrl() ?? ""}
          alt="preview-image"
          onLoad={handleImageLoad}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={() => {
            cropper().reset();
            if (!croppedUrl()) {
              setPreviewUrl("");
              setCroppedUrl("");
            }
            handleClose();
          }}
          color="info"
          size="large"
          sx={{ px: 3 }}
          variant="outlined"
          title="cancel"
        >
          ยกเลิก
        </Button>
        <Button
          onClick={() => {
            setState(
              "croppedImage",
              cropper().getCroppedCanvas().toDataURL("image/jpeg")
            );
            setHasCropped(true);
            setPreviewUrl(state.croppedImage);
            setCroppedUrl(state.croppedImage);
            handleClose();
          }}
          color="primary"
          size="large"
          sx={{ px: 3 }}
          variant="contained"
          title="save"
        >
          ครอบตัดและบันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
}
