import type { FirebaseApp, FirebaseOptions } from "firebase/app";
import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  setTokenAutoRefreshEnabled,
} from "firebase/app-check";

import type { Component, JSX } from "solid-js";
import { createContext } from "solid-js";

export const FirebaseContext = createContext<FirebaseApp>();

interface Props {
  config: FirebaseOptions;
  children: JSX.Element;
}

export const FirebaseProvider: Component<Props> = (props) => {
  // eslint-disable-next-line solid/reactivity
  const app = initializeApp(props.config);
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHA_SITE_KEY),
    // Optional argument. If true, the SDK automatically refreshes App Check tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });

  setTokenAutoRefreshEnabled(appCheck, true);

  return (
    <FirebaseContext.Provider value={app}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
