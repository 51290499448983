/* @refresh reload */
import { render } from "solid-js/web";
import * as Sentry from "@sentry/react";

import "./global.css";
import App from "./App";
import { Router } from "@solidjs/router";
import { FirebaseProvider } from "./hooks/FirebaseProvider";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

const DEFAULT_TIMEZONE = "Asia/Bangkok";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// https://day.js.org/docs/en/timezone/set-default-timezone#docsNav
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
// Setting the default timezone
dayjs.tz.setDefault(DEFAULT_TIMEZONE);

const root = document.getElementById("root");

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    "Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?"
  );
}

// Sentry.init({
//   dsn: "https://fecf8587e7244c7883d334c118630a0d@o1320545.ingest.sentry.io/4505260998918144",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "https://admin.cal-cal.com",
//         "https://dev-admin.cal-cal.com",
//       ],
//     }),
//     new Sentry.Replay(),
//   ],
//   enabled: import.meta.env.VITE_APP_ENV !== "localhost",
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

render(
  () => (
    <FirebaseProvider config={firebaseConfig}>
      <Router>
        <App />
      </Router>
    </FirebaseProvider>
  ),
  root!
);
