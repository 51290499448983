import { Image } from "../components/Events/CreateEvent/DragAndDropFile";

export type FetchEventsInput = {
  query?: string;
  page?: number;
  eventType?: string;
  eventGoal?: string;
  challengeType?: string;
  eventStatus?: string;
};

export type GetEvents = {
  data: Event[];
  total: number;
};

export type Event = {
  id: string;
  title: string;
  detail: string | null;
  image_url: string;
  images: string[];
  capacity: number | null;
  joined: number | null;
  event_type: EventType;
  challenge_type: ChallengeType;
  instruction: string | null;
  goal: Goal | null;
  rewards: Reward[] | null;
  conditions: string[];
  sponsors: Record<string, string>[] | null;
  code: string | null;
  start_date: string;
  end_date: string;
  created_at: string;
  youtube_url: string | null;
  status: EventStatus;
  branch_link: string | null;
  updated_by: string | null;
  updated_at: string;
  teams: Ranking[];
};

export type EventTeam = {
  total_team: number;
  ranking: Ranking[];
};

export type EventTeams = {
  total: number;
  data: Team[];
};

export type Team = {
  id: string;
  name: string;
  capacity: number;
  joined: number;
  image_url: string;
};

export type EventMembers = {
  total: number;
  sum: number;
  data: UserJoinedEvent[];
};

export type UserJoinedEvent = {
  id: string;
  joined_date: string;
  cumulative_steps: number;
  cumulative_distance: number;
  user_profile: UserProfile;
  my_rank_no: number | null;
};

export type UserProfile = {
  first_name: string;
  last_name: string;
  display_name: string;
};

export type Ranking = {
  rank_no: number;
  id: string;
  name: string;
  image_url: string;
  joined: number;
  capacity: number | null;
  is_joined: boolean;
  cumulative_steps: number;
  cumulative_distance: number;
};

export type Reward = {
  image_url: string;
  name: string;
  rank_no: string;
};

export type RewardForm = Omit<Reward, "image_url"> & { image: Image };
export type TeamForm = Pick<Ranking, "name" | "capacity"> & { image: Image };

type Goal = {
  amount: number | null;
  text: string;
  type: "step" | "distance";
};

export enum EventGoal {
  Step = "step",
  Distance = "distance",
}

export enum EventType {
  Public = "public",
  Private = "private",
}

export enum EventCapacityType {
  Limited = "limited",
  Unlimited = "unlimited",
}

export enum ChallengeType {
  Individual = "individual",
  Team = "team",
}

export enum EventStatus {
  Opened = "opened",
  Started = "started",
  Ended = "ended",
  Cancelled = "cancelled",
}

export enum ParticipantSort {
  Rank = "my_rank_no",
  JoinedDate = "joined_date",
}

export type EventIdInput = { id: string };

export type CreateEventInput = Omit<
  Event,
  | "image_url"
  | "joined"
  | "sponsors"
  | "code"
  | "created_at"
  | "status"
  | "updated_at"
  | "conditions"
  | "youtube_url"
  | "teams"
> &
  Partial<Pick<Event, "image_url" | "youtube_url">> & {
    teams: (Omit<Team, "id" | "joined" | "capacity"> & {
      capacity: number | null;
    })[];
    conditions: string;
  };

export type UpdateEventDetailInput = Pick<
  CreateEventInput,
  | "image_url"
  | "capacity"
  | "title"
  | "event_type"
  | "updated_by"
  | "start_date"
  | "end_date"
> & {
  id: string;
};

export type UpdateEventImageInput = Pick<
  CreateEventInput,
  "images" | "youtube_url" | "updated_by"
> & {
  id: string;
};

export type UpdateEventDescriptionInput = Pick<
  CreateEventInput,
  "detail" | "instruction" | "conditions" | "updated_by"
> & {
  id: string;
};

export type UpdateEventBranchLinkInput = Pick<
  CreateEventInput,
  "updated_by"
> & {
  id: string;
  branch_link?: string;
};

export type EventUpdateStatusInput = {
  id: string;
  status: EventStatus;
};

export type AddRewardInput = {
  eventId: string;
  reward: Reward;
  updated_by: string;
};

export type UpdateRewardInput = {
  eventId: string;
  reward: Reward;
  index: number;
  updated_by: string;
};

export type DeleteRewardInput = {
  eventId: string;
  rewardIndex: number;
  updated_by: string;
};

export type AddTeamInput = {
  event_id: string;
  team: { name: string; capacity?: number | null; image_url: string };
};

export type UpdateTeamInput = Pick<AddTeamInput, "team"> & {
  id: string;
};
