import { createTheme } from "@suid/material/styles";

const palette = {
  primary: {
    main: "#FF9E18",
  },
  secondary: {
    main: "#FF8426",
  },
  info: {
    main: "#0000008A",
  },
  error: {
    main: "#D32F2F",
  },
  success: {
    main: "#2E7D32",
  },
  text: {
    primary: "#000000DE",
    secondary: "#00000099",
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: ["Prompt", "sans-serif"].join(","),
  },
  components: {
    // styleOverrides is not implement yet please use global.css to workaround
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiCard: {
      defaultProps: {
        sx: { borderRadius: 4 },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: { pt: "24px !important" },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: { p: 3 },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        sx: { color: palette.text.primary },
      },
    },
  },
});
