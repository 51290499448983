import { Chip } from "@suid/material";
import { JSX } from "solid-js";
import toast from "solid-toast";

import type { ChipProps } from "@suid/material/Chip";

type CopyToClipboardChipProps = { label: string } & ChipProps;

export default function CopyToClipboardChip({
  label,
  sx,
  ...props
}: CopyToClipboardChipProps) {
  const copyToClipboard: JSX.EventHandlerUnion<HTMLElement, MouseEvent> = (
    e
  ) => {
    e.preventDefault();
    const text = e.currentTarget.innerText;
    navigator.clipboard.writeText(text);
    toast.success(`คัดลอก ${text}`);
  };

  return (
    <Chip
      label={label}
      title="Copy to clipboard"
      onClick={copyToClipboard}
      sx={{ "&:hover": { cursor: "copy" }, ...sx }}
      {...props}
    />
  );
}
