import { AxiosErrorWithData } from "./models/response";
import { TeamForm } from "./models/event";
import { Image } from "./components/Events/CreateEvent/DragAndDropFile";

export function parseURLSearchParams(object: Record<string, string>): string {
  return new URLSearchParams(object).toString();
}

export async function base64ToFile(previewUrl: string) {
  const base64Response = await fetch(previewUrl);
  const blob = await base64Response.blob();
  return new File([blob], `preview.jpg`, { type: blob.type });
}

export async function imageToFile({ dataUri, name }: Image) {
  const base64Response = await fetch(dataUri);
  const blob = await base64Response.blob();
  return new File([blob], name, { type: blob.type });
}

export const getAxiosErrorMessage = (error: AxiosErrorWithData) =>
  error.response?.data?.message ?? "เกิดข้อผิดพลาด 😔";

export function mapOptional<T, U>(
  value: T | null,
  transform: (value: NonNullable<T>) => U
): U | null;

export function mapOptional<T, U>(
  value: T | undefined,
  transform: (value: NonNullable<T>) => U
): U | undefined;

export function mapOptional<T, U>(
  value: T | null | undefined,
  transform: (value: NonNullable<T>) => U,
  defaultValue?: U
): U | null | undefined {
  if (typeof value === "undefined" || value === null) {
    return defaultValue;
  }

  return transform(value as NonNullable<T>);
}

export function getErrorFromArray(errors: any[]) {
  return errors.every((error: any) => typeof error === "string")
    ? errors.join()
    : "";
}

export function calculateTeamCapacity(teams: TeamForm[]) {
  return teams.reduce((sum, { capacity }) => sum + (capacity ?? 0), 0);
}

export async function addImagesToFormData({
  formData,
  images,
  key,
}: {
  formData: FormData;
  images: Image[];
  key: string;
}) {
  (await Promise.all(images.map((image) => imageToFile(image)))).map((file) =>
    formData.append(key, file)
  );
}

export function getFileName(url: string) {
  if (!url) return "";

  const tmp = url.split("/");
  return tmp[tmp.length - 1];
}
