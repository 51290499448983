export const unitSize = [
  {
    text: "กรัม (g.)",
    value: "g",
  },
  {
    text: "มิลลิลิตร (ml.)",
    value: "ml",
  },
];
