// TODO: change to get from api
export const foodCategory = [
  {
    text: "🍽️ ทั้งหมด",
    value: 0,
  },
  {
    text: "🍗 ของคาว",
    value: 1,
  },
  {
    text: "🍿 ของว่าง",
    value: 2,
  },
  {
    text: "🍩 ของหวาน",
    value: 3,
  },
  {
    text: "🧋 เครื่องดื่ม",
    value: 4,
  },
  {
    text: "🍯 อาหารเสริม",
    value: 5,
  },
  {
    text: "🍉 ผลไม้",
    value: 6,
  },
];
