import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@suid/material";

export default function NutrientsSummary({ data }: { data: any }) {
  return (
    <TableContainer class="table-in-detail" component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ร้านอาหาร</TableCell>
            <TableCell>หน่วยบริโภค</TableCell>
            <TableCell>Calories 🔥</TableCell>
            <TableCell>Carb 🍞</TableCell>
            <TableCell>Protein 🍗</TableCell>
            <TableCell>Fat 🫒</TableCell>
          </TableRow>
        </TableHead>
        <TableBody color="#00000099">
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Typography variant="body2" title={data.restaurant?.name_en}>
                {data.restaurant ? data.restaurant.name_th : "-"}
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row">
              <Typography variant="body2">
                {data.serving_size ?? "-"}
                {data.serving_unit ?? ""} / {data.serving_name_th}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {data.calories?.toLocaleString() ?? "-"} Kcal
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{data.carb ?? "-"} g</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{data.protein ?? "-"} g</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{data.fat ?? "-"} g</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
