import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@suid/material";
import { InputBaseProps } from "@suid/material/InputBase";
import { SelectChangeEvent } from "@suid/material/Select";
import { createSignal, For } from "solid-js";

export interface Values {
  text: string;
  label: string;
  value: string;
}

type FormProps = {
  setData(key: string, value: any): void;
  name: string;
  label: string;
  required?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  values: Values[];
  initialValue?: string[];
};

export default function CheckmarksSelector(componentProps: FormProps) {
  const [value, setValue] = createSignal<string[]>(
    componentProps.initialValue ?? []
  );
  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;

    setValue(typeof value === "string" ? value.split(",") : value);
    componentProps.setData(componentProps.name, value);
  };

  return (
    <FormControl fullWidth required={componentProps?.required}>
      <InputLabel id="label">{componentProps.label}</InputLabel>
      <Select
        labelId="label"
        name={componentProps.name}
        multiple
        label={componentProps.label}
        value={value()}
        onChange={handleChange}
        input={<OutlinedInput label={componentProps.label} />}
        renderValue={(selected) => {
          const inputTexts = selected.map((value) => {
            const found = componentProps.values.find(
              (info) => info.value === value
            );
            return found ? found.label : null;
          });
          return inputTexts.join(", ");
        }}
      >
        <For each={componentProps.values}>
          {(data) => (
            <MenuItem value={data.value}>
              <Checkbox checked={value().indexOf(data.value) > -1} />
              <ListItemText primary={data.text} />
            </MenuItem>
          )}
        </For>
      </Select>
    </FormControl>
  );
}
