import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@suid/material";
import useAuth from "../hooks/useAuth";
import logo from "../public/logo-yellow.webp";
import { useFirebaseApp } from "../hooks/useFirebaseApp";

const Login = () => {
  const app = useFirebaseApp();
  const { logIn } = useAuth(app);

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background:
          "radial-gradient(86.88% 100.04% at 95.97% 28.98%, rgba(253, 172, 50, 0.1764) 0%, rgba(255, 243, 224, 0) 100%)",
      }}
    >
      <Card raised sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              CalCal Admin
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              ({import.meta.env.VITE_APP_ENV ?? "Develop"})
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="large" onClick={logIn}>
              Sign in
            </Button>
          </CardActions>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 150 }}
          image={logo}
          alt="CalCal logo"
        />
      </Card>
    </Box>
  );
};

export default Login;
