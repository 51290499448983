import type { Food } from "./food";

export type Report = {
  id: string;
  uid: string;
  title: string;
  description: string;
  type: ReportType;
  food_id: string;
  topics: string[];
  status: ReportStatus;
  reason: string | null;
  updated_by: string;
  updated_at: string;
  created_at: string;
};

export type ReportWithFood = Omit<Report, "food_id"> & {
  food: Food;
};

export enum ReportType {
  Food = "อาหาร",
}

export enum ReportStatus {
  Pending = "pending",
  Rejected = "rejected",
  Resolved = "resolved",
}

export type GetReports = {
  data: Report[];
  total: number;
};

export type FetchReportsInput = {
  query?: string;
  page?: number;
  foodReportTopicIds: string[];
  reportStatus: string;
};

export type FetchReportInput = { id: string };

export type UpdateReportInput = Partial<
  Pick<Report, "reason" | "updated_by" | "status">
> & {
  id: string;
};
