import { FirebaseApp, FirebaseError } from "firebase/app";
import {
  GoogleAuthProvider,
  User,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { onCleanup } from "solid-js";
import { createStore, reconcile } from "solid-js/store";
import toast from "solid-toast";

const provider = new GoogleAuthProvider();
// provider.addScope("https://www.googleapis.com/auth/fitness.body.read");
// provider.addScope("https://www.googleapis.com/auth/fitness.activity.read");
// provider.addScope("https://www.googleapis.com/auth/user.gender.read");
// provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
// provider.addScope("https://www.googleapis.com/auth/userinfo.email");

export default function useAuth(app: FirebaseApp) {
  const auth = getAuth(app);
  const [authState, setAuthState] = createStore<{
    user: User | null;
    error: Error | null;
    loading: boolean;
  }>({
    user: auth.currentUser,
    error: null,
    loading: auth.currentUser === null,
  });

  const logIn = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        setAuthState(
          reconcile({
            user: data.user,
            loading: false,
            error: null,
          })
        );
      })
      .catch((error: FirebaseError) => {
        toast.error(error.message);
        console.error(error);
      });
  };

  const logOut = () => {
    signOut(auth)
      .then(() => {
        setAuthState(
          reconcile({
            user: null,
            loading: false,
            error: null,
          })
        );
      })
      .catch((error: FirebaseError) => {
        toast.error(error.message);
        console.error(error);
      });
  };

  const getToken = () => {
    return auth.currentUser ? getIdToken(auth.currentUser) : null;
  };

  // Setup Listeners
  const unsub = onAuthStateChanged(
    auth,
    (user) => {
      user &&
        setAuthState(
          reconcile({
            user,
            loading: false,
            error: null,
          })
        );

      if (!user) {
        setAuthState(
          reconcile({
            user: null,
            loading: false,
            error: null,
          })
        );
      }
    },
    (error) => {
      error &&
        setAuthState(
          reconcile({
            user: null,
            loading: false,
            error: error,
          })
        );
    },
    () => {
      setAuthState(
        reconcile({
          user: null,
          loading: false,
          error: null,
        })
      );
    }
  );

  onCleanup(unsub);

  return { authState, logIn, logOut, getToken };
}
