import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
} from "@suid/material";
import FoodIcon from "@suid/icons-material/FastfoodRounded";
import ExerciseIcon from "@suid/icons-material/FitnessCenterRounded";
import EventIcon from "@suid/icons-material/EventRounded";
import QueryStatsIcon from "@suid/icons-material/QueryStatsRounded";
import ArticleIcon from "@suid/icons-material/ArticleRounded";
import ReportIcon from "@suid/icons-material/ReportRounded";
import UserIcon from "@suid/icons-material/PeopleRounded";
import LogoutIcon from "@suid/icons-material/LogoutRounded";
import ExpandMoreIcon from "@suid/icons-material/ExpandMore";
import ExpandLessIcon from "@suid/icons-material/ExpandLess";

import { Component, JSX, createMemo, createSignal } from "solid-js";
import logo from "./public/logo-text.svg";
import { useLocation } from "@solidjs/router";
import { User } from "firebase/auth";
import LogoutDialog from "./components/Modal/LogoutModal";
import { Collapse } from "solid-collapse";

interface Props {
  children: JSX.Element;
  user: User | null;
}

const drawerWidth = 240;

const Layout: Component<Props> = (props: Props) => {
  const location = useLocation();
  const pathname = createMemo(() => location.pathname);
  const [open, setOpen] = createSignal(false);
  const [isExpanded, setIsExpanded] = createSignal(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   const c = children(() => props.children);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          open
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          <Toolbar sx={{ mx: "auto", my: 1 }}>
            <img src={logo} alt="logo" />
          </Toolbar>
          <List disablePadding>
            <ListItem>
              <ListItemButton onClick={() => setIsExpanded(!isExpanded())}>
                <ListItemIcon>
                  <FoodIcon />
                </ListItemIcon>
                <ListItemText primary="อาหาร" />
                {isExpanded() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse value={isExpanded()} class="menu-transition">
              <List disablePadding>
                <ListItemButton
                  component="a"
                  sx={{ padding: "0.5rem 1rem", margin: "0.5rem 1rem" }}
                  href="/"
                  selected={
                    pathname() === "/" || pathname().startsWith("/foods")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="รายการอาหาร" />
                </ListItemButton>
                <ListItemButton
                  component="a"
                  sx={{ padding: "0.5rem 1rem", margin: "0.5rem 1rem" }}
                  href="/restaurants"
                  selected={pathname().startsWith("/restaurants")}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="ร้านอาหาร" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItem>
              <ListItemButton
                component="a"
                href="/exercises"
                selected={pathname().startsWith("/exercises")}
              >
                <ListItemIcon>
                  <ExerciseIcon />
                </ListItemIcon>
                <ListItemText primary="ออกกำลังกาย" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                component="a"
                href="/events"
                selected={pathname().startsWith("/events")}
              >
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="จัดการกิจกรรม" />
              </ListItemButton>
            </ListItem>
            {/* <ListItem>
              <ListItemButton
                disabled
                component="a"
                href="/users"
                selected={pathname().startsWith("/users")}
              >
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="ผู้ใช้งาน" />
              </ListItemButton>
            </ListItem> */}
            <ListItem>
              <ListItemButton
                component="a"
                href="/reports"
                selected={pathname().startsWith("/reports")}
              >
                <ListItemIcon>
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText primary="รายงานปัญหา" />
              </ListItemButton>
            </ListItem>
            {/* <ListItem>
              <ListItemButton
                component="a"
                href="/stats"
                selected={pathname() === "/stats"}
              >
                <ListItemIcon>
                  <QueryStatsIcon />
                </ListItemIcon>
                <ListItemText primary="สถิติการใช้งาน" />
              </ListItemButton>
            </ListItem> */}
            <ListItem>
              <ListItemButton
                component="a"
                href={import.meta.env.VITE_DIRECTUS_URL}
                target="_self"
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="จัดการบทความ" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />

          <List>
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  onClick={handleOpen}
                  edge="end"
                  title="logout"
                  aria-label="logout"
                >
                  <LogoutIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  alt={props.user?.uid}
                  src={props.user?.photoURL ?? ""}
                />
              </ListItemAvatar>

              <Link
                component="a"
                color="inherit"
                underline="hover"
                href={`/users/${props.user?.uid}`}
              >
                <ListItemText
                  primary={
                    props.user?.providerData[0].displayName ?? props.user?.email
                  }
                />
              </Link>
            </ListItem>
          </List>
        </Drawer>
        <Stack sx={{ position: "relative", width: "100%" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#FAFAFA",
              width: "100%",
              height: "100vh",
            }}
          >
            {props.children}
          </Box>
        </Stack>
      </Box>
      <LogoutDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default Layout;
