export type Exercise = {
  id: string;
  name_th: string;
  name_en: string;
  image_url: string;
  level: ExerciseLevel;
  met: number;
  updated_by?: string;
  updated_at: string;
  is_verified: boolean;
  description_th?: string;
  description_en?: string;
  category_id?: number;
  category_name_th?: string;
  category_name_en?: string;
};

export enum ExerciseLevel {
  Moderate = "moderate",
  Vigorous = "vigorous",
  Light = "light",
}

export type FetchExercisesInput = {
  query?: string;
  page?: number;
};

export type FetchExerciseInput = { id: string };

export type GetExercises = {
  data: Exercise[];
  total: number;
};

export type CreateExerciseInput = Omit<
  Exercise,
  "category_name_th" | "category_name_en"
>;

export type UpdateExerciseInput = Partial<Omit<CreateExerciseInput, "id">> & {
  id: string;
};

export type DeleteExerciseInput = { id: string };
