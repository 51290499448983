export const foodFilters = [
  {
    text: "📷 ไม่มีภาพ",
    label: "📷 ไม่มีภาพ",
    value: "no-image",
  },
  {
    text: " ไม่มีสารอาหาร",
    label: "🍌 ไม่มีสารอาหาร",
    value: "no-nutrient",
  },
  {
    text: "ไม่มีปริมาณหน่วย",
    label: "‼️ ไม่มีปริมาณหน่วย",
    value: "no-unit-size",
  },
  {
    text: " ไม่มีหน่วยตวง",
    label: "‼️ ไม่มีหน่วยตวง",
    value: "no-unit",
  },
  {
    text: "หน่วยบริโภคเดี่ยว",
    label: "🫗 หน่วยบริโภคเดี่ยว",
    value: "single-unit-size",
  },
  {
    text: "🛒 ไม่มีบาร์โค๊ด",
    label: "🛒 ไม่มีบาร์โค๊ด",
    value: "no-sku",
  },
  {
    text: "📚 ไม่มีแหล่งที่มา",
    label: "📚 ไม่มีแหล่งที่มา",
    value: "no-ref",
  },
  {
    text: "❌ ยังไม่ได้ยืนยันข้อมูล",
    label: "❌ ยังไม่ได้ยืนยันข้อมูล",
    value: "no-verify",
  },
];
