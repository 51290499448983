import { Edit, Delete, CheckCircle, Error } from "@suid/icons-material";
import {
  Grow,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
  Stack,
  Typography,
  IconButton,
} from "@suid/material";

import { formatISO } from "../../formatter";
import NutrientsSummary from "./NutrientsSummary";
import CopyToClipboardChip from "../Global/CopyToClipboardChip";
import { brokenImageUrl } from "../../data/defaultImage";

type Props = {
  handleOpen(): void;
  handleDeleteFood(data: any): void;
  fetchFoodData(id: string): void;
  index(): number;
  food: any;
};

export default function FoodSummaryCard({
  handleOpen,
  handleDeleteFood,
  fetchFoodData,
  index,
  food,
}: Props) {
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(index() > 1 ? { timeout: 1000 } : {})}
    >
      <Card
        sx={{
          position: "relative",
          display: "flex",
          borderRadius: "16px",
        }}
      >
        <CardActionArea
          component="a"
          href={`/foods/${food.id}`}
          sx={{
            margin: 2,
            alignSelf: "start",
            width: 238,
            overflow: "hidden",
            borderRadius: 2,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 238,
              height: 158,
              aspectRatio: 3 / 2,
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: 2,
              objectFit: "cover",
            }}
            width={238}
            height={158}
            loading="lazy"
            image={food.image_url}
            onError={(e) => {
              e.currentTarget.src = brokenImageUrl;
            }}
            alt={food.name_th}
          />
        </CardActionArea>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">{food.name_th}</Typography>
              {food.is_verified ? (
                <CheckCircle color="success" />
              ) : (
                <Error color="error" />
              )}
            </Stack>
            <Typography variant="body1" paddingBottom={1}>
              {food.name_en}
            </Typography>
          </Box>
          <NutrientsSummary data={food} />
          <Stack
            mt={2}
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>ID:</Typography>
              <CopyToClipboardChip label={food.id} />
            </Stack>
            <Typography variant="body2">
              แก้ไขล่าสุดโดย : {food.updated_by ?? ""}{" "}
              {food.updated_at ? formatISO(food.updated_at) : ""}
            </Typography>
          </Stack>
        </CardContent>
        <Stack position="absolute" right={1} top={1} p={2} direction="row">
          <IconButton
            title="edit"
            onClick={async () => {
              await fetchFoodData(food.id);
              handleOpen();
            }}
          >
            <Edit />
          </IconButton>
          <IconButton title="delete" onClick={() => handleDeleteFood(food)}>
            <Delete />
          </IconButton>
        </Stack>
      </Card>
    </Grow>
  );
}
