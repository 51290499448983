import { createSelect, createAsyncOptions } from "@thisbeyond/solid-select";
import "@thisbeyond/solid-select/style.css";
import {
  createEffect,
  mergeProps,
  splitProps,
  on,
  Show,
  For,
  ParentComponent,
  VoidComponent,
} from "solid-js";
import {
  Box,
  TextField,
  FormControl,
  MenuItem,
  Popover,
  List,
} from "@suid/material";
import { InputBaseProps } from "@suid/material/InputBase";
import type { TextFieldProps } from "@suid/material/TextField";
import { GetRestaurants, Restaurant } from "../../models/restaurant";
import { getRestaurant } from "../../services/restaurant";
import { api } from "../../services/axios";
import { API_URL } from "../../config";

type SelectProps = Parameters<typeof createSelect>[0] & {
  id?: string;
  name?: any;
  label?: string;
  error?: string | string[];
  touched?: boolean;
  format?: (data: any, type: "option" | "value") => any;
  placeholder?: string;
  readonly?: boolean;
  loading?: boolean;
  loadingPlaceholder?: string;
  emptyPlaceholder?: string;
  onBlur?: (event: Event) => void;
};

const fetchData = async (inputValue: string): Promise<Restaurant[]> => {
  const { data } = await api.get<GetRestaurants>(
    `${API_URL}/restaurants/admin?query=${inputValue}&page=1&limit=10`
  );
  return data.data;
};

let fieldRef!: HTMLDivElement;

const Select: VoidComponent<SelectProps> = (props) => {
  const [selectProps, local] = splitProps(
    mergeProps(
      {
        format: (data: any, type: "option" | "value") => data,
        placeholder: "",
        readonly: typeof props.options !== "function",
        loading: false,
        loadingPlaceholder: "Loading...",
        emptyPlaceholder: "ไม่มีข้อมูล",
      },
      props
    ),
    [
      "options",
      "optionToValue",
      "multiple",
      "isOptionDisabled",
      "disabled",
      "onInput",
      "onChange",
    ]
  );
  const select = createSelect(selectProps);

  createEffect(
    on(
      () => local.initialValue,
      async (value) => {
        if (value != null) {
          const raw = await getRestaurant(value);
          if (raw) {
            select.setValue(raw);
          }
        }
      }
    )
  );

  return (
    <>
      <TextField
        ref={fieldRef}
        id={local.id}
        // name={local.name}
        label={local.label}
        error={local.touched && local.error != null}
        disabled={select.disabled}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: (
            <Show when={select.hasValue()}>
              <Show when={!select.multiple && !select.hasInputValue()}>
                <Box position="absolute" sx={{ pointerEvents: "none" }}>
                  {`${select.value()?.name_th} (${select.value()?.name_en})`}
                </Box>
              </Show>
            </Show>
          ),
        }}
        onFocusIn={select.onFocusIn}
        onFocusOut={select.onFocusOut}
        onBlur={local.onBlur}
        onClick={(event) => {
          // console.log(select.hasInputValue());
          // console.log(select.hasValue());
          // select.toggleOpen();
        }}
        onKeyDown={select.onKeyDown}
        onChange={(_, value) => {
          select.setInputValue(value);
        }}
        type="search"
        autoComplete="off"
        value={select.inputValue()}
        placeholder={select.hasValue() ? "" : props.placeholder}
      />
      <Popover
        sx={{
          width: "100%",
          minWidth: "500px",
          overflow: "hidden",
        }}
        open={select.isOpen()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onMouseDown={select.onMouseDown}
        anchorEl={fieldRef}
        transition
      >
        <List sx={{ width: "100%", minWidth: "500px", overflow: "auto" }}>
          <Show
            when={!local.loading}
            fallback={
              <OptionListPlaceholder>
                {local.loadingPlaceholder}
              </OptionListPlaceholder>
            }
          >
            <For
              each={select.options()}
              fallback={
                <OptionListPlaceholder>
                  {local.emptyPlaceholder}
                </OptionListPlaceholder>
              }
            >
              {(option) => (
                <MenuItem
                  onClick={() => select.pickOption(option)}
                  selected={select.isOptionFocused(option)}
                  disabled={select.isOptionDisabled(option)}
                >
                  {`${option.name_th} (${option.name_en})`}
                </MenuItem>
              )}
            </For>
          </Show>
        </List>
      </Popover>
    </>
  );
};

const OptionListPlaceholder: ParentComponent = (props) => {
  return <MenuItem disabled>{props.children}</MenuItem>;
};

type FormProps = {
  setData(key: string, value: any): void;
  name: string;
  label: string;
  initialValue?: string | null;
  required?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  errorText?: () => string &
    Omit<TextFieldProps, "name" | "helperText" | "error">;
};

export const FormAutoComplete = ({
  name,
  label,
  required,
  setData,
  initialValue,
}: FormProps) => {
  const onChange = (selected: Restaurant) => {
    setData(name, selected?.id ?? null);
  };

  const props = createAsyncOptions(fetchData, 500);

  return (
    <FormControl fullWidth required={required}>
      <Select
        name={name}
        label={label}
        onChange={onChange}
        initialValue={initialValue}
        {...props}
        options={props.options as Restaurant[]}
      />
    </FormControl>
  );
};
