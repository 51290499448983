import { API_URL } from "../config";
import axios, { AxiosRequestConfig } from "axios";
import { getAuth, getIdToken } from "firebase/auth";

const http = axios.create({ baseURL: API_URL });

/* eslint-disable  @typescript-eslint/no-explicit-any */
const listeners: ((
  error: Error,
  request: AxiosRequestConfig & { _retry?: boolean }
) => any | PromiseLike<any>)[] = [];

http.interceptors.request.use(async (config) => {
  const headers = config.headers ?? {};
  const token = await getAuthToken();

  if (token) {
    config.headers = {
      ...headers,
      authorization: token,
    };
  }
  return config;
});

export const api = {
  async request<T = any>(...params: Parameters<(typeof http)["request"]>) {
    return http.request<T>(...params);
  },
  async get<T = any>(...params: Parameters<(typeof http)["get"]>) {
    return http.get<T>(...params);
  },
  async post<T = any>(...params: Parameters<(typeof http)["post"]>) {
    return http.post<T>(...params);
  },
  async put<T = any>(...params: Parameters<(typeof http)["put"]>) {
    return http.put<T>(...params);
  },
  async patch<T = any>(...params: Parameters<(typeof http)["patch"]>) {
    return http.patch<T>(...params);
  },
  async delete<T = any>(...params: Parameters<(typeof http)["delete"]>) {
    return http.delete<T>(...params);
  },
  onError(
    handler: (
      error: Error,
      request: AxiosRequestConfig & { _retry?: boolean }
    ) => void
  ): () => void {
    listeners.push(handler);

    return () => {
      const index = listeners.findIndex((h) => h === handler);

      if (index !== -1) listeners.splice(index, 1);
    };
  },
};

async function getAuthToken() {
  try {
    const app = getAuth();
    const token = app.currentUser ? await getIdToken(app.currentUser) : null;

    return token ? `Bearer ${token}` : null;
  } catch (error) {
    console.error("getAuthToken", error);
  }
}
