import { Chip, Stack } from "@suid/material";
import { For } from "solid-js";
import { foodCategory } from "../../data/foodCategory";
import foodFilterStore from "../../stores/foodFilterStore";
import { useSearchParams } from "@solidjs/router";

export default function FoodCategoryMenu() {
  const { setCategory, category } = foodFilterStore;
  const [, setSearchParams] = useSearchParams();

  return (
    <Stack direction="row" spacing={2}>
      <For each={foodCategory}>
        {(data) => (
          <Chip
            label={data.text}
            component="button"
            clickable
            variant={category() === data.value ? "outlined" : "filled"}
            onClick={() => {
              setCategory(data.value);
              setSearchParams({ category: data.value });
            }}
          />
        )}
      </For>
    </Stack>
  );
}
