import { z } from "zod";

export const UnitEnum = z.enum(["g", "ml"]);

type UnitEnum = z.infer<typeof UnitEnum>;

export type Food = {
  id: string;
  image_url: string | null;
  name_th: string;
  name_en: string;
  category_id: number;
  calories: number | null;
  carb: number | null;
  protein: number | null;
  fat: number | null;
  dietary_fiber: number | null;
  sugar: number | null;
  cholesterol: number | null;
  saturated_fat: number | null;
  sodium: number | null;
  vitamin_a: number | null;
  vitamin_b1: number | null;
  vitamin_b2: number | null;
  calcium: number | null;
  iron: number | null;
  potassium: number | null;
  sku: Record<string, string>[] | null;
  serving_name_th: string;
  serving_name_en: string;
  serving_unit: UnitEnum;
  serving_size: number | null;
  keywords: string[] | null;
  allergen_info: string[] | null;
  ref_source: string | null;
  restaurant_id: string | null;
  updated_by: string | null;
  is_verified: boolean;
  updated_at: string | null;
  category_name_th: string;
  category_name_en: string;
  restaurant_name_th: string | null;
  restaurant_name_en: string | null;
};

export type FoodForm = Omit<
  Food,
  | "id"
  | "image_url"
  | "updated_at"
  | "category_name_th"
  | "category_name_en"
  | "restaurant_name_th"
  | "restaurant_name_en"
>;
