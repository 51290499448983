import {
  ChallengeType,
  EventCapacityType,
  EventGoal,
  EventStatus,
  EventType,
} from "./models/event";
import { ExerciseLevel } from "./models/exercise";
import { ReportStatus } from "./models/report";
import dayjs from "dayjs";

export function formatISO(iso: string, format = "DD/MM/YYYY HH:mm") {
  return dayjs(iso).format(format);
}

export function formatStartEnd(
  start: string,
  end: string,
  format = "DD/MM/YYYY"
) {
  return `${dayjs(start).format(format)} - ${dayjs(end).format(format)}`;
}

const exerciseLevelMapper = {
  [ExerciseLevel.Light]: "ต่ำ",
  [ExerciseLevel.Moderate]: "กลาง",
  [ExerciseLevel.Vigorous]: "สูง",
};

export function formatExerciseLevel(level: ExerciseLevel) {
  return exerciseLevelMapper[level];
}

const reportStatusMapper = {
  [ReportStatus.Pending]: "รอแก้ไข",
  [ReportStatus.Resolved]: "แก้ไขแล้ว",
  [ReportStatus.Rejected]: "ปฏิเสธ",
};

export function formatReportStatus(status: ReportStatus) {
  return reportStatusMapper[status];
}

const reportStatusColorMapper = {
  [ReportStatus.Pending]: "warning.main",
  [ReportStatus.Resolved]: "success.main",
  [ReportStatus.Rejected]: "grey.500",
};

export function formatReportStatusColor(status: ReportStatus) {
  return reportStatusColorMapper[status];
}

const eventStatusMapper = {
  [EventStatus.Cancelled]: "ยกเลิก",
  [EventStatus.Ended]: "สิ้นสุดแล้ว",
  [EventStatus.Opened]: "เปิดรับสมัคร",
  [EventStatus.Started]: "เริ่มแล้ว",
};

export function formatEventStatus(status: EventStatus) {
  return eventStatusMapper[status];
}

const EventStatusColorMapper = {
  [EventStatus.Cancelled]: "grey.500",
  [EventStatus.Ended]: "error.main",
  [EventStatus.Opened]: "warning.main",
  [EventStatus.Started]: "success.main",
};

export function formatEventStatusColor(status: EventStatus) {
  return EventStatusColorMapper[status];
}

const ChallengeTypeMapper = {
  [ChallengeType.Individual]: "เดี่ยว",
  [ChallengeType.Team]: "ทีม",
};
export function formatChallengeType(status: ChallengeType) {
  return ChallengeTypeMapper[status];
}

const eventTypeMapper = {
  [EventType.Public]: "Public",
  [EventType.Private]: "Private",
};
export function formatEventType(type: EventType) {
  return eventTypeMapper[type];
}

const eventGoalMapper = {
  [EventGoal.Step]: "สะสมก้าวเดิน",
  [EventGoal.Distance]: "สะสมระยะทาง",
};
export function formatEventGoal(goal: EventGoal) {
  return eventGoalMapper[goal];
}

const eventCapacityTypeMapper = {
  [EventCapacityType.Limited]: "จำกัด",
  [EventCapacityType.Unlimited]: "ไม่จำกัด",
};
export function formatEventCapacityType(type: EventCapacityType) {
  return eventCapacityTypeMapper[type];
}
