import {
  AddAPhoto,
  AttachFile,
  Delete,
  Edit,
  Crop,
} from "@suid/icons-material";
import { JSX, Show, createEffect, createSignal, onCleanup } from "solid-js";
import {
  Box,
  CardMedia,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@suid/material";
import { useTheme } from "@suid/material/styles";
import { Card } from "@suid/material";
import toast from "solid-toast";

import fileStore from "../../stores/uploadStore";
import ImageCropperModal from "../Modal/ImageCropperModal";

const maxFileSize = 10 * 1024 * 1024; // Maximum file size in bytes (10 MB)

export enum DragAndDropMode {
  FoodImage = "foodImage",
  RestaurantLogo = "restaurantLogo",
  ExerciseImage = "exerciseImage",
}

export enum PreviewShape {
  Square = "square",
  Circle = "circle",
}

const imageCropperProps = {
  [DragAndDropMode.FoodImage]: {
    ratio: 3 / 2,
    size: {
      width: 300,
      height: 200,
    },
  },
  [DragAndDropMode.RestaurantLogo]: {
    ratio: 1,
    size: {
      width: 240,
      height: 240,
    },
  },
  [DragAndDropMode.ExerciseImage]: {
    ratio: 3 / 2,
    size: {
      width: 300,
      height: 200,
    },
  },
};

const borderRadiusMapper = {
  [PreviewShape.Square]: 2,
  [PreviewShape.Circle]: 50,
};

export default function DragAndDropFile({
  initialImage,
  mode = DragAndDropMode.FoodImage,
  previewShape = PreviewShape.Square,
  name = "image",
}: {
  initialImage?(): string | null;
  mode?: DragAndDropMode;
  previewShape?: PreviewShape;
  name: string;
}) {
  const theme = useTheme();
  const [file, setFile] = createSignal<File | null>(null);
  const [isDragOver, setIsDragOver] = createSignal(false);
  const [showCropModal, setShowCropModal] = createSignal(false);
  const { setPreviewUrl, croppedUrl, setCroppedUrl } = fileStore;

  const ratio = imageCropperProps[mode].ratio;
  const previewSize = imageCropperProps[mode].size;
  const borderRadius = borderRadiusMapper[previewShape];

  createEffect(async () => {
    if (initialImage && typeof initialImage() === "string") {
      try {
        setCroppedUrl(initialImage() as string);
        setPreviewUrl(initialImage() as string);
        const fileResponse = await fetch(initialImage() as string);
        const blob = await fileResponse.blob();
        setFile(() => new File([blob], `preview.jpg`, { type: blob.type }));
      } catch (error) {
        setCroppedUrl("");
        setPreviewUrl("");
        setFile(null);
        toast.error(
          "ไม่สามารถโหลดภาพได้ เนื่องจากภาพต้นฉบับมีปัญหา โปรดอัพโหลดภาพใหม่"
        );
        console.error(error);
      }
    }
  });

  onCleanup(() => {
    setCroppedUrl("");
    setPreviewUrl("");
    setFile(null);
  });

  const handleCloseCropModal = () => {
    if (!croppedUrl()) {
      setFile(null);
    }
    setShowCropModal(false);
  };

  const handleDrop: JSX.EventHandler<HTMLDivElement, DragEvent> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileData(e.dataTransfer?.files?.[0] as File);
  };

  const handleDragOver: JSX.EventHandler<HTMLDivElement, DragEvent> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave: JSX.EventHandler<HTMLDivElement, DragEvent> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleFileInput: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileData(e.currentTarget?.files?.[0] as File);
  };

  const resetFileInput: JSX.EventHandlerUnion<HTMLInputElement, MouseEvent> = (
    e
  ) => {
    const element = e.target as HTMLInputElement;
    element.value = "";
  };

  const setFileData = (file: File) => {
    if (file && file.size > maxFileSize) {
      toast.error("ไฟล์มีขนาดใหญ่เกินขนาดสูงสุด 10MB โปรดเลือกภาพใหม่");
      return;
    }
    if (file) {
      setFile(() => file);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreviewUrl("");
    setCroppedUrl("");
    setIsDragOver(false);
  };

  // This effect generates a preview URL for the selected file
  createEffect(() => {
    if (!file()) {
      setPreviewUrl("");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewUrl(e.target?.result as string);
      setIsDragOver(false);
      if (file()?.name !== "preview.jpg") {
        setShowCropModal(true);
      }
      // const blob = new Blob([e.target?.result as ArrayBuffer], {
      //   type: file()?.type,
      // });
    };
    reader.readAsDataURL(file() as Blob);
  });

  return (
    <>
      <Grid
        container
        spacing={{
          lg: 2,
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              ...previewSize,
              borderRadius,
              overflow: "hidden",
              border: "1px dashed rgba(0, 0, 0, 0.23)",
              textAlign: "center",
              backgroundColor: isDragOver()
                ? theme.palette.grey[300]
                : theme.palette.grey[100],
              pointerEvents: file() ? "none" : "auto",
            }}
            onDrop={handleDrop}
            onDragEnter={handleDragOver}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <Show when={!croppedUrl()}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  ...previewSize,
                  borderRadius,
                  padding: "2rem",
                }}
              >
                <label for="image-picker">
                  <IconButton
                    title="Browse file"
                    component="label"
                    for="image-picker"
                  >
                    <AddAPhoto fontSize="large" />
                    <input
                      id="image-picker"
                      name={name}
                      type="file"
                      accept="image/*"
                      onChange={handleFileInput}
                      onClick={resetFileInput}
                      hidden
                    />
                  </IconButton>
                </label>
                <Typography variant="body2">
                  เลือก หรือ วางไฟล์ภาพที่นี่เพื่ออัพโหลด
                </Typography>
                <Typography variant="body2">(ขนาดไม่เกิน 10MB)</Typography>
              </Stack>
            </Show>
            <Show when={croppedUrl()}>
              <Card sx={{ position: "relative", display: "flex" }}>
                <CardMedia
                  component="img"
                  sx={{ ...previewSize, borderRadius, objectFit: "cover" }}
                  image={croppedUrl()}
                  alt="preview-image"
                />
              </Card>
            </Show>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Show when={croppedUrl() && file()}>
            <Box sx={{ flexGrow: 1, width: "100%" }}>
              <List>
                <ListItem
                  secondaryAction={
                    <Stack direction="row">
                      <IconButton
                        onClick={() => setShowCropModal(true)}
                        edge="start"
                        title="Crop"
                        aria-label="crop image"
                      >
                        <Crop />
                      </IconButton>
                      <IconButton
                        component="label"
                        for="image-picker"
                        title="Edit"
                        aria-label="change file"
                      >
                        <input
                          id="image-picker"
                          name="image"
                          type="file"
                          accept="image/*"
                          onInput={handleFileInput}
                          hidden
                        />
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={handleRemoveFile}
                        edge="end"
                        title="Delete"
                        aria-label="delete file"
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemIcon>
                    <AttachFile />
                  </ListItemIcon>
                  <ListItemText
                    primary={file()?.name ?? ""}
                    secondary={`${Number(
                      Number(file()?.size) / 1024 / 1024
                    ).toFixed(2)} MB ${file()?.type}`}
                  />
                </ListItem>
              </List>
            </Box>
          </Show>
        </Grid>
      </Grid>
      <ImageCropperModal
        open={showCropModal}
        handleClose={handleCloseCropModal}
        aspect={ratio}
      />
    </>
  );
}
