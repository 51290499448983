import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useMediaQuery,
  useTheme,
} from "@suid/material";
import { TransitionProps } from "@suid/material/transitions";
import { JSXElement } from "solid-js";
import useAuth from "../../hooks/useAuth";
import { useFirebaseApp } from "../../hooks/useFirebaseApp";

const Transition = function Transition(
  props: TransitionProps & {
    children: JSXElement;
  }
) {
  return <Slide direction="up" {...props} />;
};

export default function LogoutDialog({
  open,
  handleClose,
}: {
  open(): boolean;
  handleClose(): void;
}) {
  const app = useFirebaseApp();
  const { logOut } = useAuth(app);
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullWidth={fullWidth()}
      open={open()}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>ยืนยันการออกจากระบบ</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          คุณแน่ใจนะว่าต้องการออกจากระบบ?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="info"
          size="large"
          sx={{ px: 3 }}
          variant="outlined"
        >
          ไม่ใช่
        </Button>
        <Button
          onClick={() => {
            handleClose();
            logOut();
          }}
          color="error"
          size="large"
          sx={{ px: 3 }}
          variant="contained"
        >
          ใช่
        </Button>
      </DialogActions>
    </Dialog>
  );
}
