import { useSearchParams } from "@solidjs/router";
import { Add, NavigateNext, Search } from "@suid/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Stack,
  TextField,
  Typography,
} from "@suid/material";
import {
  For,
  Match,
  Switch,
  createEffect,
  createMemo,
  createResource,
  createSignal,
} from "solid-js";
import FoodCategoryMenu from "../components/Foods/FoodCategoryMenu";
import FoodFilterSelector from "../components/Foods/FoodFilterSelector";
import FoodSummaryCard from "../components/Foods/FoodSummaryCard";
import FoodsDialog from "../components/Foods/FoodsDialog";
import RemoveFoodDialog from "../components/Foods/RemoveFoodModal";
import PaginationFooter from "../components/Global/PaginationFooter";
import { API_URL } from "../config";
import useAuth from "../hooks/useAuth";
import { useFirebaseApp } from "../hooks/useFirebaseApp";
import foodFilterStore from "../stores/foodFilterStore";

const Foods = () => {
  const app = useFirebaseApp();
  const { getToken } = useAuth(app);
  const [searchTerm, setSearchTerm] = createSignal("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { foodFilter, category } = foodFilterStore;

  const fetchFoods = async ({
    searchTerm,
    category,
    filter,
    page,
  }: {
    searchTerm: string;
    category: number | null;
    filter: string | null;
    page: string | number;
  }) => {
    const token = await getToken();

    return (
      await fetch(
        `${API_URL}/foods/admin?query=${encodeURIComponent(
          searchTerm
        )}&filter=${filter}&limit=20&page=${page}${
          category ? `&category= ${category}` : ""
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
    ).json();
  };

  const derivedState = createMemo(() => {
    return {
      category: category(),
      searchTerm: searchTerm(),
      filter: foodFilter().join(","),
      page: searchParams?.page ?? 1,
    };
  });

  const [foodsResult, { refetch }] = createResource(derivedState, fetchFoods, {
    initialValue: { total: 0, data: [] },
  });
  const [dialogType, setDialogType] = createSignal<"create" | "edit">("create");
  const [openFoodDialog, setOpenFoodDialog] = createSignal(false);
  const [foodData, setFoodData] = createSignal(null);

  const handleOpen = (type: "edit" | "create") => {
    setOpenFoodDialog(true);
    setDialogType(type);
  };

  const handleClose = () => {
    setOpenFoodDialog(false);
    setDialogType("create");
  };

  const [openRemoveDialog, setOpenRemoveDialog] = createSignal(false);
  const handleOpenRemoveDialog = () => setOpenRemoveDialog(true);
  const handleCloseRemoveDialog = () => setOpenRemoveDialog(false);

  const [foodId, setFoodId] = createSignal<string>("");
  const [foodName, setFoodName] = createSignal<string>("");

  createEffect(() => {
    // console.log("Food filters value is", foodFilter().join(","));
    if (!foodsResult.loading && searchTerm().length > 0) {
      if (Number(searchParams?.page) > Math.ceil(foodsResult().total / 20)) {
        setSearchParams({ page: 1 });
      }
    }
  });

  const handleDeleteFood = async (food: any) => {
    setFoodId(food.id);
    setFoodName(food.name_th);
    handleOpenRemoveDialog();
  };

  const fetchFoodData = async (foodId: string) => {
    const token = await getToken();
    const res = await fetch(`${API_URL}/foods/admin/${foodId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setFoodData(data);
  };

  return (
    <>
      <Stack p={2.5} spacing={2}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNext fontSize="small" />}
        >
          <Typography>รายการอาหาร</Typography>
        </Breadcrumbs>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack direction="row" spacing={2} width={"100%"}>
            <TextField
              hiddenLabel
              id="search-input"
              placeholder="ค้นหารายการอาหาร"
              fullWidth
              type="search"
              value={searchTerm()}
              onChange={(_event, value) => setSearchTerm(value)}
              InputProps={{
                startAdornment: <Search sx={{ mr: 0.5 }} />,
              }}
              sx={{ flexGrow: 3 }}
            />
            <FoodFilterSelector />
            {/* <Button
              color="info"
              variant="outlined"
              sx={{
                borderRadius: "16px",
                borderColor: "#0000003B",
                backgroundColor: "white",
              }}
            >
              <FilterList />
            </Button> */}
          </Stack>
          <Button
            sx={{ width: "200px", borderRadius: "16px" }}
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setFoodData(null);
              handleOpen("create");
            }}
          >
            เพิ่มเมนูอาหาร
          </Button>
        </Stack>
        <FoodCategoryMenu />
        <Box>
          <Switch>
            <Match when={foodsResult.error}>
              <Typography variant="body1">{foodsResult.error}</Typography>
            </Match>
            <Match when={foodsResult.loading}>
              <Typography variant="body1">👀 กำลังค้นหา รอแป๊บ...</Typography>
            </Match>
            <Match when={foodsResult.state === "ready"}>
              <Stack spacing={1}>
                <Typography variant="body1">
                  พบทั้งหมด {foodsResult()?.total?.toLocaleString()} รายการ
                </Typography>
                <Stack spacing={2}>
                  <For each={foodsResult().data}>
                    {(food, index) => (
                      <FoodSummaryCard
                        food={food}
                        index={index}
                        handleOpen={() => handleOpen("edit")}
                        handleDeleteFood={(food) => handleDeleteFood(food)}
                        fetchFoodData={(id) => fetchFoodData(id)}
                      />
                    )}
                  </For>
                </Stack>
              </Stack>
            </Match>
          </Switch>
        </Box>
      </Stack>
      {foodsResult.state === "ready" && (
        <PaginationFooter
          pages={() => Math.ceil(foodsResult().total / 20)}
          setSearchParams={setSearchParams}
          currentPage={searchParams?.page}
        />
      )}
      <FoodsDialog
        open={openFoodDialog}
        handleClose={handleClose}
        type={dialogType}
        data={foodData}
        refetch={refetch}
      />
      <RemoveFoodDialog
        open={openRemoveDialog}
        handleClose={handleCloseRemoveDialog}
        id={foodId}
        foodName={() => foodName()}
        refetch={refetch}
      />
    </>
  );
};

export default Foods;
