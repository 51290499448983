export const foodServing = [
  {
    id: 1,
    text: "จาน (Dish)",
    value: "จาน",
    en_value: "dish",
  },
  {
    id: 2,
    text: "ชาม (Bowl)",
    value: "ชาม",
    en_value: "bowl",
  },
  {
    id: 3,
    text: "ถ้วย (Bowl)",
    value: "ถ้วย",
    en_value: "bowl",
  },
  {
    id: 4,
    text: "กล่อง (Pack)",
    value: "กล่อง",
    en_value: "pack",
  },
  {
    id: 5,
    text: "ชุด (Set)",
    value: "ชุด",
    en_value: "set",
  },
  {
    id: 6,
    text: "ขวด (Bottle)",
    value: "ขวด",
    en_value: "bottle",
  },
  {
    id: 7,
    text: "แก้ว (Glass)",
    value: "แก้ว",
    en_value: "glass",
  },
  {
    id: 8,
    text: "กระป๋อง (Can)",
    value: "กระป๋อง",
    en_value: "can",
  },
  {
    id: 9,
    text: "กระปุก (Jar)",
    value: "กระปุก",
    en_value: "jar",
  },
  {
    id: 10,
    text: "ถุง (Bag)",
    value: "ถุง",
    en_value: "bag",
  },
  {
    id: 11,
    text: "ห่อ (Pack)",
    value: "ห่อ",
    en_value: "pack",
  },
  {
    id: 12,
    text: "ช้อนโต๊ะ (Table Spoon)",
    value: "ช้อนโต๊ะ",
    en_value: "table spoon",
  },
  {
    id: 13,
    text: "โคน (Cone)",
    value: "โคน",
    en_value: "cone",
  },
  {
    id: 14,
    text: "ชิ้น (Slice)",
    value: "ชิ้น",
    en_value: "slice",
  },
  {
    id: 15,
    text: "ซองเล็ก (Small pack)",
    value: "ซองเล็ก",
    en_value: "small_pack",
  },
  {
    id: 16,
    text: "ช้อนชา (Teaspoon)",
    value: "ช้อนชา",
    en_value: "teaspoon",
  },
  {
    id: 17,
    text: "หลอด (Container)",
    value: "หลอด",
    en_value: "container",
  },
];
