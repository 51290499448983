import {
  CircularProgress,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
} from "@suid/material";
import { Component, Match, Switch, lazy } from "solid-js";

import { Route, Routes } from "@solidjs/router";
import Layout from "./Layout";
import Foods from "./pages/Foods";
import Login from "./pages/Login";
import { theme } from "./theme";

import { MetaProvider, Title } from "@solidjs/meta";
import useAuth from "./hooks/useAuth";
import { useFirebaseApp } from "./hooks/useFirebaseApp";
import { Toaster } from "solid-toast";

const App: Component = () => {
  const app = useFirebaseApp();
  const { authState } = useAuth(app);

  return (
    <>
      <MetaProvider>
        <Title>CalCal Admin 🥗</Title>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Match when={!authState.user && !authState.loading}>
              <Login />
            </Match>
            <Match when={!authState.user && authState.loading}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Grid>
            </Match>
            <Match when={authState.error}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Typography paragraph>
                  {JSON.stringify(authState.error)}
                </Typography>
              </Grid>
            </Match>
            <Match when={authState.user}>
              <Layout user={authState.user}>
                <Routes>
                  <Route path="/" component={Foods} />
                  <Route path="/foods" component={Foods} />
                  <Route
                    path="/foods/:id"
                    component={lazy(() => import("./pages/FoodDetail"))}
                  />
                  <Route
                    path="/restaurants"
                    component={lazy(() => import("./pages/Restaurants"))}
                  />
                  <Route
                    path="/exercises"
                    component={lazy(() => import("./pages/Exercises"))}
                  />
                  <Route
                    path="/exercises/:id"
                    component={lazy(() => import("./pages/ExerciseDetail"))}
                  />
                  <Route
                    path="/events"
                    component={lazy(() => import("./pages/Events"))}
                  />
                  <Route
                    path="/events/:id"
                    component={lazy(() => import("./pages/EventDetail"))}
                  />
                  <Route
                    path="/users"
                    component={lazy(() => import("./pages/Users"))}
                  />
                  <Route
                    path="/users/:uid"
                    component={lazy(() => import("./pages/UserDetail"))}
                  />
                  <Route
                    path="/reports"
                    component={lazy(() => import("./pages/Reports"))}
                  />
                  <Route
                    path="/reports/:id"
                    component={lazy(() => import("./pages/ReportDetail"))}
                  />
                  <Route
                    path="/stats"
                    component={lazy(() => import("./pages/Stats"))}
                  />
                </Routes>
              </Layout>
            </Match>
          </Switch>
        </ThemeProvider>
      </MetaProvider>
      <Toaster />
    </>
  );
};

export default App;
