import { createForm } from "@felte/solid";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
} from "@suid/material";
import CloseIcon from "@suid/icons-material/Close";

import fileStore from "../../stores/uploadStore";

import { DialogContent, Divider, Stack } from "@suid/material";
import useTheme from "@suid/material/styles/useTheme";
import { createEffect, onCleanup, onMount } from "solid-js";
import toast from "solid-toast";
import { foodAllergenInfo } from "../../data/foodAllergenInfo";
import { foodCategory } from "../../data/foodCategory";
import { unitSize } from "../../data/unitSize";
import useAuth from "../../hooks/useAuth";
import CheckmarksSelector from "../Form/CheckmarksSelector";
import DragAndDropFile, { DragAndDropMode } from "../Form/DragAndDropFile";
import FormSelector from "../Form/FormSelector";
import { useFirebaseApp } from "../../hooks/useFirebaseApp";
import { z } from "zod";
import { API_URL } from "../../config";
import { base64ToFile, getAxiosErrorMessage } from "../../utils";
import { foodServing } from "../../data/foodServing";
import { FoodForm, UnitEnum } from "../../models/food";
import { useNavigate } from "@solidjs/router";
import { noFoodImageUrl } from "../../data/defaultImage";
import { FormAutoComplete } from "../Form/FormAutoComplete";

const schema = z.object({
  name_th: z.string().nonempty(),
  name_en: z.string().nonempty(),
  category_id: z.number().gte(1).lte(6),
  calories: z.number().gte(0).lte(2000).nullable(),
  carb: z.number().gte(0).lte(2000).nullable(),
  protein: z.number().gte(0).lte(2000).nullable(),
  fat: z.number().gte(0).lte(2000).nullable(),
  dietary_fiber: z.number().gte(0).lte(2000).nullable(),
  sugar: z.number().gte(0).lte(2000).nullable(),
  cholesterol: z.number().gte(0).lte(2000).nullable(),
  saturated_fat: z.number().gte(0).lte(2000).nullable(),
  sodium: z.number().gte(0).lte(2000).nullable(),
  vitamin_a: z.number().gte(0).lte(2000).nullable(),
  vitamin_b1: z.number().gte(0).lte(2000).nullable(),
  vitamin_b2: z.number().gte(0).lte(2000).nullable(),
  calcium: z.number().gte(0).lte(2000).nullable(),
  iron: z.number().gte(0).lte(2000).nullable(),
  potassium: z.number().gte(0).lte(2000).nullable(),
  sku: z.object({ name: z.string(), sku: z.string() }).array().nullable(),
  serving_name_th: z.string().nonempty(),
  serving_name_en: z.string().nonempty(),
  serving_unit: UnitEnum,
  serving_size: z.number().gte(0).lte(2000).nullable(),
  keywords: z.string().array().nullable(),
  allergen_info: z.string().array().nullable(),
  ref_source: z.string().nullable(),
  restaurant_id: z.string().nullable(),
  updated_by: z.string().nullable(),
  is_verified: z.boolean(),
});

export default function FoodsDialog({
  open,
  handleClose,
  type,
  data,
  refetch,
}: {
  open(): boolean;
  handleClose(): void;
  type(): "create" | "edit";
  data(): any | null;
  refetch(): Promise<any>;
}) {
  const app = useFirebaseApp();
  const { getToken, authState } = useAuth(app);
  const navigate = useNavigate();
  const theme = useTheme();
  const { previewUrl, hasCropped, setHasCropped } = fileStore;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onError = (error: string) => {
    toast.dismiss();
    toast.error(error);
    console.error(error);
  };

  const { form, reset, setInitialValues, isSubmitting, isDirty, setData } =
    createForm<z.infer<typeof schema>>({
      // extend: validator({ schema }),
      onError: (error) => onError(error as string),
      onSubmit: async (values) => {
        if (type() === "create") {
          await uploadFoodData(values);
        } else {
          await updateFoodData(values);
        }
      },
    });

  const updateFoodData = async (value: FoodForm) => {
    const token = await getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const payload = {
      ...value,
      image_url:
        previewUrl() && hasCropped()
          ? `${import.meta.env.VITE_CDN_URL}/foods/${data().id}.webp`
          : previewUrl() && !hasCropped()
          ? data().image_url
          : noFoodImageUrl,
      category_id: Number(value.category_id),
      serving_name_th: value.serving_name_th,
      serving_name_en:
        foodServing.filter(
          (serving) => serving.value === value.serving_name_th
        )[0]?.en_value ?? value.serving_name_th,
      sku: value.sku ? [{ name: value.name_en, sku: value.sku }] : null,
      allergen_info: value.allergen_info ?? null,
      keywords: value.keywords
        ? String(value.keywords).trim().split(",")
        : null,
      ref_source: value.ref_source ? [value.ref_source] : null,
      restaurant_id: value.restaurant_id ?? null,
      updated_by: authState.user?.providerData[0].displayName ?? "admin",
    };

    if (previewUrl() && hasCropped()) {
      const file = await base64ToFile(previewUrl());
      const formData = new FormData();
      formData.append("image", file as File);
      formData.append("foodId", data().id as string);
      const uploadImage = await fetch(`${API_URL}/foods/upload`, {
        method: "PUT",
        headers,
        body: formData,
      });

      await toast.promise(uploadImage.json(), {
        loading: "กำลังอัพโหลดภาพ",
        success: "อัพโหลดภาพสำเร็จ ✅",
        error: getAxiosErrorMessage,
      });

      setHasCropped(false);
    }

    const updateFood = await fetch(`${API_URL}/foods/${data().id}`, {
      method: "PATCH",
      headers: { ...headers, "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    await toast.promise(updateFood.json(), {
      loading: "กำลังบันทึกรายการอาหาร",
      success: (result) => {
        if (result.statusCode >= 400) {
          throw new Error(`เกิดข้อผิดพลาด ${result.message}`);
        }
        return "บันทึกเมนูอาหารสำเร็จ ✅";
      },
      error: getAxiosErrorMessage,
    });

    await refetch();
    handleClose();
  };

  const uploadFoodData = async (value: FoodForm) => {
    const token = await getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${API_URL}/generate/id`, {
      method: "GET",
      headers: { ...headers, "Content-Type": "application/json" },
    });
    const { id }: { id: string } = await res.json();

    const payload = {
      ...value,
      id,
      image_url: previewUrl()
        ? `${import.meta.env.VITE_CDN_URL}/foods/${id}.webp`
        : noFoodImageUrl,
      category_id: Number(value.category_id),
      serving_name_th: value.serving_name_th ?? "จาน",
      serving_name_en:
        foodServing.filter(
          (serving) => serving.value === value.serving_name_th
        )[0]?.en_value ?? value.serving_name_th,
      sku: value.sku ? [{ name: value.name_en, sku: value.sku }] : null,
      allergen_info: value.allergen_info ?? null,
      keywords: value.keywords
        ? String(value.keywords).trim().split(",")
        : null,
      ref_source: value.ref_source ? [value.ref_source] : null,
      restaurant_id: value.restaurant_id ?? null,
      updated_by: authState.user?.providerData[0].displayName ?? "admin",
    };

    const createFood = await fetch(`${API_URL}/foods`, {
      method: "POST",
      headers: { ...headers, "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    await toast.promise(createFood.json(), {
      loading: "กำลังสร้างรายการอาหาร",
      success: (result) => {
        if (result.statusCode >= 400) {
          throw new Error(`เกิดข้อผิดพลาด ${result.message}`);
        }
        return "สร้างเมนูอาหารสำเร็จ ✅";
      },
      error: getAxiosErrorMessage,
    });

    if (previewUrl() && hasCropped()) {
      const file = await base64ToFile(previewUrl());
      const formData = new FormData();
      formData.append("image", file as File);
      formData.append("foodId", id as string);

      const uploadImage = await fetch(`${API_URL}/foods/upload`, {
        method: "PUT",
        headers,
        body: formData,
      });

      await toast.promise(uploadImage.json(), {
        loading: "กำลังอัพโหลดภาพ",
        success: "อัพโหลดภาพสำเร็จ ✅",
        error: getAxiosErrorMessage,
      });

      setHasCropped(false);
    }

    await refetch();
    handleClose();
    navigate(`/foods/${id}`);
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  createEffect(() => {
    if (type() === "edit") {
      setInitialValues({
        name_th: data().name_th,
        name_en: data().name_en,
        category_id: data().category_id ?? 1,
        calories: data().calories,
        carb: data().carb ?? null,
        protein: data().protein ?? null,
        fat: data().fat ?? null,
        sodium: data().sodium ?? null,
        sugar: data().sugar ?? null,
        dietary_fiber: data().dietary_fiber ?? null,
        saturated_fat: data().saturated_fat ?? null,
        cholesterol: data().cholesterol ?? null,
        vitamin_a: data().vitamin_a ?? null,
        vitamin_b1: data().vitamin_b1 ?? null,
        vitamin_b2: data().vitamin_b2 ?? null,
        calcium: data().calcium ?? null,
        iron: data().iron ?? null,
        potassium: data().potassium ?? null,
        serving_name_th: data().serving_name_th ?? null,
        serving_name_en: data().serving_name_en ?? null,
        serving_unit: data().serving_unit ?? "g",
        serving_size: data().serving_size ?? null,
        sku: data().sku ? data().sku[0].sku : null,
        allergen_info: data().allergen_info ?? null,
        keywords: data().keywords ?? null,
        ref_source: data().ref_source ?? null,
        restaurant_id: null,
        updated_by: data().updated_by ?? null,
        is_verified: data().is_verified ?? false,
      });
      reset();
    } else {
      setInitialValues({
        name_th: "",
        name_en: "",
        category_id: 1,
        calories: null,
        carb: null,
        protein: null,
        fat: null,
        sodium: null,
        sugar: null,
        dietary_fiber: null,
        saturated_fat: null,
        cholesterol: null,
        vitamin_a: null,
        vitamin_b1: null,
        vitamin_b2: null,
        calcium: null,
        iron: null,
        potassium: null,
        serving_name_th: "",
        serving_name_en: "",
        serving_unit: "g",
        serving_size: null,
        sku: null,
        allergen_info: null,
        keywords: null,
        ref_source: null,
        restaurant_id: null,
        updated_by: authState.user?.providerData[0].displayName ?? "admin",
        is_verified: false,
      });
      reset();
    }
  });

  onMount(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onCleanup(() => {
    document.removeEventListener("keydown", handleKeydown);
  });

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen()}
      maxWidth={"lg"}
      open={open()}
      TransitionComponent={Slide}
      onClose={handleClose}
    >
      <form use:form>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8, m: 0 }}
          edge="end"
          title="close"
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography variant="h6">
            {type() === "create" ? "เพิ่ม" : "แก้ไข"}เมนูอาหาร
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} divider={<Divider flexItem />}>
            <DragAndDropFile
              initialImage={() => data()?.image_url ?? null}
              name="food_image"
              mode={DragAndDropMode.FoodImage}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" mb={2} component="h6">
                ข้อมูลอาหาร
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="name_th"
                    name="name_th"
                    label="ชื่อ TH"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="name_en"
                    name="name_en"
                    label="ชื่อ EN"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormAutoComplete
                    setData={setData}
                    name="restaurant_id"
                    label="ร้านอาหาร"
                    initialValue={data()?.restaurant_id ?? null}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormSelector
                    setData={setData}
                    name="category_id"
                    label="หมวดหมู่"
                    required
                    values={foodCategory.slice(1)}
                    initialValue={data()?.category_id ?? 1}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="calories"
                    name="calories"
                    label="พลังงาน (Kcal)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="serving_size"
                    name="serving_size"
                    label="ปริมาณ"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormSelector
                    setData={setData}
                    name="serving_unit"
                    label="หน่วยตวง"
                    values={unitSize}
                    required
                    initialValue={data()?.serving_unit ?? "g"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormSelector
                    setData={setData}
                    name="serving_name_th"
                    label="หน่วยบริโภค"
                    values={foodServing}
                    required
                    initialValue={
                      data()?.serving_name_th
                        ? foodServing.filter(
                            (value) =>
                              value.value === data()?.serving_name_th ?? "จาน"
                          )[0]?.value
                        : "จาน"
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" mb={2} component="h6">
                ข้อมูลโภชนาการ
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="carb"
                    name="carb"
                    label="คาร์โบไฮเดรต (g.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="protein"
                    name="protein"
                    label="โปรตีน (g.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="fat"
                    name="fat"
                    label="ไขมัน (g.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="dietary_fiber"
                    name="dietary_fiber"
                    label="ใยอาหาร (g.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="sugar"
                    name="sugar"
                    label="น้ำตาล (g.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="cholesterol"
                    name="cholesterol"
                    label="คอเลสเตอรอล (mg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="saturated_fat"
                    name="saturated_fat"
                    label="ไขมันอิ่มตัว (g.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="sodium"
                    name="sodium"
                    label="โซเดียม (mg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="calcium"
                    name="calcium"
                    label="แคลเซียม (mg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="potassium"
                    name="potassium"
                    label="โพแทสเซียม (mg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="vitamin_a"
                    name="vitamin_a"
                    label="วิตามินเอ (mcg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="vitamin_b1"
                    name="vitamin_b1"
                    label="วิตามินบี 1 (mcg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="vitamin_b2"
                    name="vitamin_b2"
                    label="วิตามินบี 2 (mcg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="iron"
                    name="iron"
                    label="เหล็ก (mg.)"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 2000,
                      inputMode: "numeric",
                      pattern: "^d*(.d{0,1})?$",
                      step: ".1",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" mb={2} component="h6">
                ข้อมูลเพิ่มเติม
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="sku"
                    name="sku"
                    label="บาร์โค้ด (รหัสสินค้า)"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CheckmarksSelector
                    setData={setData}
                    name="allergen_info"
                    label="ข้อมูลสำหรับผู้แพ้อาหาร"
                    values={foodAllergenInfo}
                    initialValue={data()?.allergen_info ?? []}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="keywords"
                    name="keywords"
                    label="คำที่ใช้ค้นหา (ใช้ ',' เพื่อแยกเป็นคำใหม่)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="ref_source"
                    name="ref_source"
                    label="แหล่งที่มา"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={data()?.is_verified ?? false}
                    name="is_verified"
                  />
                }
                label="ยืนยันข้อมูล"
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
            color="info"
            size="large"
            variant="outlined"
          >
            ยกเลิก
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting()}
            size="large"
            variant="contained"
          >
            บันทึก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
